.accordion {
    background-color: #fff;
    color: #444;
    cursor: pointer;
    padding: 18px;
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
    font-size: 15px;
    transition: 0.4s;
  }
  
  .accordion-div .active, .accordion:hover {
    background-color: #ccc;
  }
  
  .accordion-div .accordion:after {
    content: '\002B';
    color: #777;
    font-weight: bold;
    float: right;
    margin-left: 5px;
  }

  .accordion-div .active:after {
    content: "\2212";
  }

  .panel {
    padding: 0 18px;
    background-color: rgb(243, 242, 242);
    max-height: 0;
    overflow: scroll;
    transition: max-height 0.2s ease-out;
  }